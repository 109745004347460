import React from 'react'

const SiteGraphReportComponent = () => {
    return (
        <div className='bg-slate-50 p-4 h-screen'>
            <div className='bg-white p-4 shadow-xl rounded-xl'>
                <div className='font-semibold text-lg text-[#28507B]'>Site Graph Report</div>
                <div className='flex mt-8 gap-6'>
                    <div className='w-1/4 '>
                        <input type="text"
                            className='border w-full p-2 rounded-lg mt-2'
                            placeholder='Enter Site ID'
                        />
                    </div>
                    <div className='w-1/4 '>
                        <input type="date"
                            className='border w-full p-2 rounded-lg mt-2'  
                        />
                        <div className='text-xs text-slate-500 font-semibold mt-1'>Select Report Date</div>
                    </div>
                    <button className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-lg'>
                        Search
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SiteGraphReportComponent
