import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import AddClusterComponent from '../../components/AddClusterComponent.js/AddClusterComponent'

const AddCluster = () => {
    return (
        <div>
            <div className='flex'>
                <div className='w-[15%]'>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <AddClusterComponent/>
                </div>
            </div>
        </div>
    )
}

export default AddCluster
