import React from 'react'

const Dashboard = () => {
    return (
        <div className=' bg-slate-50'>
            <div className='grid grid-cols-4 p-6 gap-4'>
                <a href="/project/manageproject">
                    <div className='flex  p-4 gap-3 rounded-xl shadow-lg bg-white hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/Project.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>PROJECTS</div>
                            <div className='font-semibold text-md'>1</div>
                        </div>
                    </div>
                </a>

                <a href="/zonecircle">
                    <div className='flex  p-4 gap-3 rounded-xl shadow-lg bg-white hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/Zone.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>ZONES</div>
                            <div className='font-semibold text-md'>4</div>
                        </div>
                    </div>
                </a>

                <a href="/zonecircle">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/Circle.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>CIRCLES</div>
                            <div className='font-semibold text-md'>14</div>
                        </div>
                    </div>
                </a>

                <a href="/cluster/managecluster">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/Region.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>CLUSTERS</div>
                            <div className='font-semibold text-md'>88</div>
                        </div>
                    </div>
                </a>

                <a href="/city/managecity">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/City.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>CITIES</div>
                            <div className='font-semibold text-md'>577</div>
                        </div>
                    </div>
                </a>

                <a href="/site/managesite">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/Location.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>SITES</div>
                            <div className='font-semibold text-md'>580</div>
                        </div>
                    </div>
                </a>

                <a href="/psu/managepsu">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/RMS.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>PSU DEVICES</div>
                            <div className='font-semibold text-md'>711</div>
                        </div>
                    </div>
                </a>

                <a href="report/faultreport">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/fault.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>ALL FAULTS</div>
                            <div className='font-semibold text-md'>351</div>
                        </div>
                    </div>
                </a>

                <a href="report/acfailreport">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/ac.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>AC VOLTAGE FAULT</div>
                            <div className='font-semibold text-md'>125</div>
                        </div>
                    </div>
                </a>

                <a href="/dcvoltageleak">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/dc.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>DC VOLTAGE FAULTS</div>
                            <div className='font-semibold text-md'>50</div>
                        </div>
                    </div>
                </a>

                <a href="/earthingfault">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/earth.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>EARTHING FAULT</div>
                            <div className='font-semibold text-md'>35</div>
                        </div>
                    </div>
                </a>

                <a href="/temperaturefault">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/Temp.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>TEMEPERATURE FAULT</div>
                            <div className='font-semibold text-md'>1</div>
                        </div>
                    </div>
                </a>

                <a href="ethernetfault">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/ethernet.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>ETHERNET FAULT</div>
                            <div className='font-semibold text-md'>108</div>
                        </div>
                    </div>
                </a>

                <a href="batteryfault">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/InternalBatteryFault.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>PSU BATTERY FAULT</div>
                            <div className='font-semibold text-md'>38</div>
                        </div>
                    </div>
                </a>

                <a href="/weaksignal">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/GSMSignal.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>PSU WEAK SIGNALS</div>
                            <div className='font-semibold text-md'>67</div>
                        </div>
                    </div>
                </a>

                <a href="/heartbeatfail">
                    <div className='flex bg-white p-4 gap-3 rounded-xl shadow-lg hover:scale-105 ease-out duration-300'>
                        <div>
                            <img width={60} src="https://ripl.org.in/TMS/img/RMSFault.jpg" alt="" />
                        </div>
                        <div className=''>
                            <div className='text-sm font-semibold text-[#28507B]'>PSU HEARTBEAT FAIL</div>
                            <div className='font-semibold text-md'>127</div>
                        </div>
                    </div>
                </a>

            </div>
        </div>
    )
}

export default Dashboard
