import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import FaultReportComponent from '../../components/FaultReportComponent/FaultReportComponent'

const FaultReport = () => {
    return (
        <div>
            <div className='flex'>
                <div className='w-[15%]'>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <FaultReportComponent/>
                </div>
            </div>
        </div>
    )
}

export default FaultReport
