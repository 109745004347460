import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import AddClientComponent from '../../components/AddClientComponent/AddClientComponent'

const AddClient = () => {
  return (
    <div>
      <div className='flex'>
        <div className='w-[15%]'>
          <Sidebar />
        </div>
        <div>
          <Navbar />
          <AddClientComponent/>
        </div>
      </div>
    </div>
  )
}

export default AddClient
