import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddPSUComponent = () => {
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [zones, setZones] = useState([]);
  const [circles, setCircles] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [cities, setCities] = useState([]);
  const [sites, setSites] = useState([]);

  const [selectedClient, setSelectedClient] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedCircle, setSelectedCircle] = useState('');
  const [selectedCluster, setSelectedCluster] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedSite, setSelectedSite] = useState('');

  const [imei, setImei] = useState('');
  const [rmsMobileNo, setRmsMobileNo] = useState('');
  const [acVoltageHigh, setAcVoltageHigh] = useState('');
  const [acVoltageLow, setAcVoltageLow] = useState('');
  const [earthVoltageHigh, setEarthVoltageHigh] = useState('');
  const [earthVoltageLow, setEarthVoltageLow] = useState('');
  const [dcVoltageHigh, setDcVoltageHigh] = useState('');
  const [dcVoltageLow, setDcVoltageLow] = useState('');
  const [tempHigh, setTempHigh] = useState('');
  const [tempLow, setTempLow] = useState('');
  const [batteryCutOff, setBatteryCutOff] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [logDelay, setLogDelay] = useState('');

  const fetchData = async () => {
    const { data } = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/.json');
    const clients = Object.keys(data.clients).map(key => ({
      id: key,
      ...data.clients[key],
    }));
    setClients(clients);
    const zones = Object.keys(data.zones).map(key => ({
      id: key,
      ...data.zones[key],
    }));
    setZones(zones);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    setSelectedClient(clientId);
    const client = clients.find(client => client.id === clientId);
    const projects = client.projects ? Object.keys(client.projects).map(key => ({
      id: key,
      ...client.projects[key],
    })) : [];
    setProjects(projects);
  };

  const handleZoneChange = (e) => {
    const zoneId = e.target.value;
    setSelectedZone(zoneId);
    const zone = zones.find(zone => zone.id === zoneId);
    const circles = zone.circles ? Object.keys(zone.circles).map(key => ({
      id: key,
      ...zone.circles[key],
    })) : [];
    setCircles(circles);
  };

  const handleCircleChange = (e) => {
    const circleId = e.target.value;
    setSelectedCircle(circleId);
    const circle = circles.find(circle => circle.id === circleId);
    const clusters = circle.clusters ? Object.keys(circle.clusters).map(key => ({
      id: key,
      ...circle.clusters[key],
    })) : [];
    setClusters(clusters);
  };

  const handleClusterChange = (e) => {
    const clusterId = e.target.value;
    setSelectedCluster(clusterId);
    const cluster = clusters.find(cluster => cluster.id === clusterId);
    const cities = cluster.cities ? Object.keys(cluster.cities).map(key => ({
      id: key,
      ...cluster.cities[key],
    })) : [];
    setCities(cities);
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setSelectedCity(cityId);
    const city = cities.find(city => city.id === cityId);
    const sites = city.sites ? Object.keys(city.sites).map(key => ({
      id: key,
      ...city.sites[key],
    })) : [];
    setSites(sites);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const client = clients.find(c => c.id === selectedClient);
    const project = projects.find(p => p.id === selectedProject);
    const zone = zones.find(z => z.id === selectedZone);
    const circle = circles.find(c => c.id === selectedCircle);
    const cluster = clusters.find(c => c.id === selectedCluster);
    const city = cities.find(c => c.id === selectedCity);
    const site = sites.find(s => s.id === selectedSite);

    const data = {
      client: client ? client.name : '',
      project: project ? project.name : '',
      zone: zone ? zone.name : '',
      circle: circle ? circle.name : '',
      cluster: cluster ? cluster.name : '',
      city: city ? city.name : '',
      site: site ? site.siteName : '',
      imei,
      rmsMobileNo,
      acVoltageHigh,
      acVoltageLow,
      earthVoltageHigh,
      earthVoltageLow,
      dcVoltageHigh,
      dcVoltageLow,
      tempHigh,
      tempLow,
      batteryCutOff,
      latitude,
      longitude,
      logDelay,
    };

    await axios.post('https://rachpower-dad00-default-rtdb.firebaseio.com/psuDevices.json', data);
  };

  return (
    <div className='bg-slate-50 p-4 h-screen'>
      <div className='bg-white p-4 shadow-xl rounded-xl '>
        <div className='font-semibold text-lg text-[#28507B]'>Add PSU Device</div>
        <form onSubmit={handleSubmit}>
          <div className='flex mt-8 gap-6'>
            <div className='w-1/3 '>
              <div className='text-slate-600'>Select Client</div>
              <select onChange={handleClientChange} value={selectedClient} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent'>
                <option value="">Select Client</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>{client.name}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select Client</div>
            </div>
            <div className='w-1/3 '>
              <div className='text-slate-600'>Select Project</div>
              <select onChange={e => setSelectedProject(e.target.value)} value={selectedProject} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent'>
                <option value="">Select Project</option>
                {projects.map(project => (
                  <option key={project.id} value={project.id}>{project.name}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select Project</div>
            </div>
            <div className='w-1/3 '>
              <div className='text-slate-600'>Select Zone</div>
              <select onChange={handleZoneChange} value={selectedZone} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent'>
                <option value="">Select Zone</option>
                {zones.map(zone => (
                  <option key={zone.id} value={zone.id}>{zone.name}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select Zone</div>
            </div>
          </div>

          <div className='flex mt-6 gap-6'>
            <div className='w-1/3 '>
              <div className='text-slate-600'>Select Circle</div>
              <select onChange={handleCircleChange} value={selectedCircle} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent'>
                <option value="">Select Circle</option>
                {circles.map(circle => (
                  <option key={circle.id} value={circle.id}>{circle.name}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select Circle</div>
            </div>
            <div className='w-1/3 '>
              <div className='text-slate-600'>Select Cluster</div>
              <select onChange={handleClusterChange} value={selectedCluster} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent'>
                <option value="">Select Cluster</option>
                {clusters.map(cluster => (
                  <option key={cluster.id} value={cluster.id}>{cluster.name}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select Cluster</div>
            </div>
            <div className='w-1/3 '>
              <div className='text-slate-600'>Select City</div>
              <select onChange={handleCityChange} value={selectedCity} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent'>
                <option value="">Select City</option>
                {cities.map(city => (
                  <option key={city.id} value={city.id}>{city.name}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select City</div>
            </div>
          </div>

          <div className='flex mt-6 gap-6'>
            <div className='w-1/3 '>
              <div className='text-slate-600'>Select Site</div>
              <select onChange={e => setSelectedSite(e.target.value)} value={selectedSite} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent'>
                <option value="">Select Site</option>
                {sites.map(site => (
                  <option key={site.id} value={site.id}>{site.siteName}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select Site</div>
            </div>

            <div className='w-1/3 '>
              <div className='text-slate-600'>Enter IMEI</div>
              <input placeholder='Enter IMEI Number' type='text' value={imei} onChange={e => setImei(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter IMEI</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter RMS Mobile No.</div>
              <input placeholder='Enter RMS Mobile No.' type='text' value={rmsMobileNo} onChange={e => setRmsMobileNo(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter RMS Mobile No.</div>
            </div>
          </div>



          <div className='flex gap-6 mt-6'>
            <div className='w-1/3'>
              <div className='text-slate-600'>AC Voltage High</div>
              <input placeholder='250' type='text' value={acVoltageHigh} onChange={e => setAcVoltageHigh(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter AC Voltage High</div>
            </div>
            <div className='w-1/3'>
              <div className='text-slate-600'>AC Voltage Low</div>
              <input placeholder='180' type='text' value={acVoltageLow} onChange={e => setAcVoltageLow(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter AC Voltage Low Threshold</div>
            </div>
            <div className='w-1/3'>
              <div className='text-slate-600'>Earth Voltage High</div>
              <input placeholder='20' type='text' value={earthVoltageHigh} onChange={e => setEarthVoltageHigh(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter Earth Voltage High Threshold</div>
            </div>
          </div>

          <div className='flex gap-6 mt-6'>
            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Earth Voltage Low</div>
              <input placeholder='0' type='text' value={earthVoltageLow} onChange={e => setEarthVoltageLow(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter Earth Voltage Low</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter DC Voltage High</div>
              <input placeholder='55' type='text' value={dcVoltageHigh} onChange={e => setDcVoltageHigh(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter DC Voltage High</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter DC Voltage Low</div>
              <input placeholder='48' type='text' value={dcVoltageLow} onChange={e => setDcVoltageLow(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter DC Voltage Low</div>
            </div>
          </div>


          <div className='flex gap-6 mt-6'>
            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Temperature High Value</div>
              <input placeholder='30' type='text' value={tempHigh} onChange={e => setTempHigh(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Temperature High Threshold</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Temperature Low Value</div>
              <input placeholder='20' type='text' value={tempLow} onChange={e => setTempLow(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Temperature Low Threshold</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Internal Battery Cut Off</div>
              <input placeholder='7' type='text' value={batteryCutOff} onChange={e => setBatteryCutOff(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Internal Battery Cut Off Threshold</div>
            </div>
          </div>

          <div className='flex gap-6 mt-6'>
            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Latitude</div>
              <input placeholder='Latitude' type='text' value={latitude} onChange={e => setLatitude(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Latitude of PSU Device</div>
            </div>
            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Longitude</div>
              <input placeholder='Longitude' type='text' value={longitude} onChange={e => setLongitude(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Longitude of PSU Device</div>
            </div>
            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Log Delay</div>
              <input placeholder='Log Delay' type='text' value={logDelay} onChange={e => setLogDelay(e.target.value)} className='border w-full p-2 rounded-lg mt-2 text-slate-600 bg-transparent' />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Frequency of Data Push on the Server</div>
            </div>
          </div>

          <button type='submit' className='bg-[#28507B] text-white font-semibold py-2 px-4 rounded-lg mt-8'>
            Add PSU Device
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddPSUComponent;