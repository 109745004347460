import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageCityComponent = () => {
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');
  const [circles, setCircles] = useState([]);
  const [selectedCircle, setSelectedCircle] = useState('');
  const [clusters, setClusters] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState('');
  const [cities, setCities] = useState([]);
  const [cityName, setCityName] = useState('');

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchCircles(selectedZone);
    } else {
      setCircles([]);
      setSelectedCircle('');
    }
  }, [selectedZone]);

  useEffect(() => {
    if (selectedCircle) {
      fetchClusters(selectedZone, selectedCircle);
    } else {
      setClusters([]);
      setSelectedCluster('');
    }
  }, [selectedCircle]);

  useEffect(() => {
    if (selectedCluster) {
      fetchCities(selectedZone, selectedCircle, selectedCluster);
    } else {
      setCities([]);
    }
  }, [selectedCluster]);

  const fetchZones = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/zones.json');
      const data = response.data;
      const loadedZones = [];
      for (const key in data) {
        loadedZones.push({
          id: key,
          name: data[key].name,
        });
      }
      setZones(loadedZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchCircles = async (zoneId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles.json`);
      const data = response.data;
      const loadedCircles = [];
      for (const key in data) {
        loadedCircles.push({
          id: key,
          name: data[key].name,
        });
      }
      setCircles(loadedCircles);
    } catch (error) {
      console.error('Error fetching circles:', error);
    }
  };

  const fetchClusters = async (zoneId, circleId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters.json`);
      const data = response.data;
      const loadedClusters = [];
      for (const key in data) {
        loadedClusters.push({
          id: key,
          name: data[key].name,
        });
      }
      setClusters(loadedClusters);
    } catch (error) {
      console.error('Error fetching clusters:', error);
    }
  };

  const fetchCities = async (zoneId, circleId, clusterId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters/${clusterId}/cities.json`);
      const data = response.data;
      const loadedCities = [];
      for (const key in data) {
        loadedCities.push({
          id: key,
          ...data[key],
          zoneName: zones.find(zone => zone.id === zoneId)?.name || 'Unknown Zone',
          circleName: circles.find(circle => circle.id === circleId)?.name || 'Unknown Circle',
          clusterName: clusters.find(cluster => cluster.id === clusterId)?.name || 'Unknown Cluster',
        });
      }
      setCities(loadedCities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleSearchCity = async () => {
    if (selectedZone && selectedCircle && selectedCluster && cityName) {
      try {
        // Perform search operation based on selected filters (zone, circle, cluster, city name)
        // Implement your search logic here
        console.log('Search:', selectedZone, selectedCircle, selectedCluster, cityName);
      } catch (error) {
        console.error('Error searching city:', error);
      }
    } else {
      alert('Please fill all the details');
    }
  };

  return (
    <div className='border p-4 h-screen bg-slate-50'>
      <div className='p-4 border rounded-xl shadow-xl bg-white'>
        <div className='font-semibold text-lg text-[#28507B]'>Manage Cities Details</div>
        <div className='flex mt-8 gap-2'>
          <div className='w-1/3'>
            <select
              value={selectedZone}
              onChange={(e) => setSelectedZone(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Zone</option>
              {zones.map(zone => (
                <option key={zone.id} value={zone.id}>{zone.name}</option>
              ))}
            </select>
          </div>
          <div className='w-1/3'>
            <select
              value={selectedCircle}
              onChange={(e) => setSelectedCircle(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Circle</option>
              {circles.map(circle => (
                <option key={circle.id} value={circle.id}>{circle.name}</option>
              ))}
            </select>
          </div>
          <div className='w-1/3'>
            <select
              value={selectedCluster}
              onChange={(e) => setSelectedCluster(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Cluster</option>
              {clusters.map(cluster => (
                <option key={cluster.id} value={cluster.id}>{cluster.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex mt-4 gap-2 mb-4'>
          <div className='w-1/3'>
            <input
              type="text"
              value={cityName}
              onChange={(e) => setCityName(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
              placeholder='City Name'
            />
          </div>
          <div className='w-1/3'>
            <input
              type="date"
              className='border border-slate-300 w-full p-2 rounded-lg text-slate-400'
            />
          </div>
          <button
            onClick={handleSearchCity}
            className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-md'
          >
            Search City
          </button>
        </div>
      </div>

      <div className='mt-6 rounded-xl shadow-xl border bg-white'>
        <div className='border my-6 bg-[#eaecf4]'>
          <div className='grid grid-cols-8 my-4 text-[#6e707e] text-[13px] font-semibold'>
            <div className='text-center'>S No</div>
            <div className='text-center'>Zone</div>
            <div className='text-center'>Circle</div>
            <div className='text-center'>Cluster</div>
            <div className='text-center'>City</div>
            <div className='text-center'>Created By</div>
            <div className='text-center'>Edit</div>
            <div className='text-center'>Delete</div>
          </div>
        </div>
        <div className='px-4'>
          {cities.map((city, index) => (
            <div key={city.id} className='grid grid-cols-8 my-2 text-sm text-[#6e707e]'>
              <div className='text-center'>{index + 1}</div>
              <div className='text-center'>{city.zoneName}</div>
              <div className='text-center'>{city.circleName}</div>
              <div className='text-center'>{city.clusterName}</div>
              <div className='text-center'>{city.name}</div>
              <div className='text-center'>CreatedBy</div>
              <div className='text-center'>
                <button className='text-[#28507B]'>Edit</button>
              </div>
              <div className='text-center'>
                <button className='text-red-600'>Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageCityComponent;