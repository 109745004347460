import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import DCVoltageLeakComponent from '../../components/DCVoltageLeakComponent/DCVoltageLeakComponent'

const DCVoltageLeak = () => {
    return (
        <div>
            <div className='flex'>
                <div className='w-[15%]'>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <DCVoltageLeakComponent/>
                </div>
            </div>
        </div>
    )
}

export default DCVoltageLeak
