import React, { useState, useEffect } from 'react';

const AddUserComponent = () => {
  const [clients, setClients] = useState({});
  const [client, setClient] = useState('');
  const [userName, setUserName] = useState('');
  const [loginId, setLoginId] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [menuAccess, setMenuAccess] = useState({
    dashboard: false,
    addClient: false,
    manageClient: false,
    addProject: false,
    manageProject: false,
    addCluster: false,
    manageCluster: false,
    addCity: false,
    manageCity: false,
    addSite: false,
    manageSite: false,
    addPsuDevice: false,
    managePsuDevice: false,
    addPsuAccess: false,
    revokePsuAccess: false
  });

  useEffect(() => {
    // Fetch client details from Firebase Realtime Database
    const fetchClients = async () => {
      try {
        const response = await fetch('https://rachpower-dad00-default-rtdb.firebaseio.com/clients.json');
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === 'checkbox') {
      setMenuAccess((prev) => ({ ...prev, [name]: checked }));
    } else {
      switch (name) {
        case 'client':
          setClient(value);
          break;
        case 'userName':
          setUserName(value);
          break;
        case 'loginId':
          setLoginId(value);
          break;
        case 'loginPassword':
          setLoginPassword(value);
          break;
        case 'mobileNumber':
          setMobileNumber(value);
          break;
        case 'email':
          setEmail(value);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!client) {
      alert('Please select a client.');
      return;
    }

    const userData = {
      client,
      userName,
      loginId,
      loginPassword,
      mobileNumber,
      email,
      menuAccess
    };

    try {
      const clientRef = `https://rachpower-dad00-default-rtdb.firebaseio.com/clients/${client}/users.json`;

      // Add user details under the chosen client
      const response = await fetch(clientRef, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      if (response.ok) {
        alert('User details added successfully!');
      } else {
        alert('Error adding user details.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while adding user details.');
    }
  };

  return (
    <div className='bg-slate-50 p-4 h-screen'>
      <div className='bg-white p-4 shadow-xl rounded-xl'>
        <div className='font-semibold text-lg text-[#28507B]'>Add User Details</div>
        <form onSubmit={handleSubmit}>
          <div className='flex mt-8 gap-6'>
            <div className='w-1/3'>
              <div className='text-slate-600'>Select Client</div>
              <select
                name='client'
                value={client}
                onChange={handleChange}
                className='border w-full p-2 rounded-lg mt-2 text-slate-600'
              >
                <option value="">Select Client</option>
                {Object.keys(clients).map((key) => (
                  <option key={key} value={key}>{clients[key].name}</option>
                ))}
              </select>
              <div className='text-xs text-slate-500 font-semibold mt-1'>Select Client</div>
            </div>
            <div className='w-1/3'>
              <div className='text-slate-600'>Enter User Name</div>
              <input
                type="text"
                name='userName'
                value={userName}
                onChange={handleChange}
                className='border w-full p-2 rounded-lg mt-2 text-slate-600'
                placeholder='Name'
              />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter Name</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Login ID</div>
              <input
                type="text"
                name='loginId'
                value={loginId}
                onChange={handleChange}
                className='border w-full p-2 rounded-lg mt-2 text-slate-600'
                placeholder='Enter Login ID'
              />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter LoginID</div>
            </div>
          </div>

          <div className='flex mt-6 gap-2 mb-6'>
            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Login Password</div>
              <input
                type="text"
                name='loginPassword'
                value={loginPassword}
                onChange={handleChange}
                placeholder='Enter Login Password'
                className='border w-full p-2 rounded-lg mt-2 text-slate-600'
              />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter Password</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Mobile Number</div>
              <input
                type="text"
                name='mobileNumber'
                value={mobileNumber}
                onChange={handleChange}
                placeholder='Enter Mobile Number'
                className='border w-full p-2 rounded-lg mt-2 text-slate-600'
              />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Mobile number</div>
            </div>

            <div className='w-1/3'>
              <div className='text-slate-600'>Enter Email</div>
              <input
                type="text"
                name='email'
                value={email}
                onChange={handleChange}
                placeholder='Enter Email'
                className='border w-full p-2 rounded-lg mt-2 text-slate-600'
              />
              <div className='text-xs text-slate-500 font-semibold mt-1'>Enter Email</div>
            </div>
          </div>

          <div className='mt-4 mb-4'>
            <div className='font-semibold text-lg text-[#28507B] border-b pb-2'>
              Assign Menu Access
            </div>
            <div className='mt-2 pb-2 border-b'>
              <div className='font-semibold text-sm'>DASHBOARD</div>
              <div className='flex gap-[415px] mt-1'>
                <div className='text-sm text-slate-600'>1. Dashboard</div>
                <div><input type="checkbox" name="dashboard" checked={menuAccess.dashboard} onChange={handleChange} /></div>
              </div>
            </div>

            <div className='mt-2 pb-2 border-b'>
              <div className='font-semibold text-sm'>CLIENTS</div>
              <div className='flex gap-[417px] mt-1'>
                <div className='text-sm text-slate-600'>1. Add Client</div>
                <div><input type="checkbox" name="addClient" checked={menuAccess.addClient} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[393px] mt-1'>
                <div className='text-sm text-slate-600'>2. Manage Client</div>
                <div><input type="checkbox" name="manageClient" checked={menuAccess.manageClient} onChange={handleChange} /></div>
              </div>
            </div>

            <div className='mt-2 pb-2 border-b'>
              <div className='font-semibold text-sm'>PROJECTS</div>
              <div className='flex gap-[410px] mt-1'>
                <div className='text-sm text-slate-600'>1. Add Project</div>
                <div><input type="checkbox" name="addProject" checked={menuAccess.addProject} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[395px] mt-1'>
                <div className='text-sm text-slate-600'>2. Manage Project</div>
                <div><input type="checkbox" name="manageProject" checked={menuAccess.manageProject} onChange={handleChange} /></div>
              </div>
            </div>

            <div className='mt-2 pb-2 border-b'>
              <div className='font-semibold text-sm'>CLUSTERS</div>
              <div className='flex gap-[420px] mt-1'>
                <div className='text-sm text-slate-600'>1. Add Cluster</div>
                <div><input type="checkbox" name="addCluster" checked={menuAccess.addCluster} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[410px] mt-1'>
                <div className='text-sm text-slate-600'>2. Manage Cluster</div>
                <div><input type="checkbox" name="manageCluster" checked={menuAccess.manageCluster} onChange={handleChange} /></div>
              </div>
            </div>

            <div className='mt-2 pb-2 border-b'>
              <div className='font-semibold text-sm'>CITIES</div>
              <div className='flex gap-[430px] mt-1'>
                <div className='text-sm text-slate-600'>1. Add City</div>
                <div><input type="checkbox" name="addCity" checked={menuAccess.addCity} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[405px] mt-1'>
                <div className='text-sm text-slate-600'>2. Manage City</div>
                <div><input type="checkbox" name="manageCity" checked={menuAccess.manageCity} onChange={handleChange} /></div>
              </div>
            </div>

            <div className='mt-2 pb-2 border-b'>
              <div className='font-semibold text-sm'>LOCATIONS</div>
              <div className='flex gap-[430px] mt-1'>
                <div className='text-sm text-slate-600'>1. Add Site</div>
                <div><input type="checkbox" name="addSite" checked={menuAccess.addSite} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[405px] mt-1'>
                <div className='text-sm text-slate-600'>2. Manage Site</div>
                <div><input type="checkbox" name="manageSite" checked={menuAccess.manageSite} onChange={handleChange} /></div>
              </div>
            </div>

            <div className='mt-2 pb-2 border-b'>
              <div className='font-semibold text-sm'>IOT DEVICES</div>
              <div className='flex gap-96 mt-1'>
                <div className='text-sm text-slate-600'>1. Add PSU Device</div>
                <div><input type="checkbox" name="addPsuDevice" checked={menuAccess.addPsuDevice} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[360px] mt-1'>
                <div className='text-sm text-slate-600'>2. Manage PSU Device</div>
                <div><input type="checkbox" name="managePsuDevice" checked={menuAccess.managePsuDevice} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[385px] mt-1'>
                <div className='text-sm text-slate-600'>3. Add PSU Access</div>
                <div><input type="checkbox" name="addPsuAccess" checked={menuAccess.addPsuAccess} onChange={handleChange} /></div>
              </div>
              <div className='flex gap-[368px] mt-1'>
                <div className='text-sm text-slate-600'>4. Revoke PSU Access</div>
                <div><input type="checkbox" name="revokePsuAccess" checked={menuAccess.revokePsuAccess} onChange={handleChange} /></div>
              </div>
            </div>
          </div>

          <button className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-lg'>
            Add User Details
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddUserComponent;