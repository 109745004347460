import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import ripl from './ripllogo.png';

const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    userid: "",
    pass: ""
  });
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmission = () => {
    if (!values.email || !values.pass) {
      setErrorMsg("Fill all fields");
      return;
    }
    setErrorMsg("");

    if (values.userid === 'admin' && values.pass === 'admin123') {
      navigate('/dashboard')
    }
    else {
      setErrorMsg("Wrong User ID or Password");
      return;
    }
    console.log(errorMsg)
  }

  return (
    <div className='bg-slate-50 h-screen pt-24'>
      <div className='w-1/2 border p-8 pb-16 mx-auto rounded-lg shadow-xl bg-white'>
        <div className=''>
          <img className='mx-auto' src={ripl} width={180} alt="" />
        </div>
        <div className='mt-8'>
          <input
            type="text"
            placeholder='Enter Login Id'
            className='border border-slate-400 p-2 w-full rounded'
            onChange={(event) =>
              setValues((prev) => ({ ...prev, userid: event.target.value }))
            }
          />
        </div>
        <div className='mt-4'>
          <input
            type="password"
            placeholder='Enter Password'
            className='border border-slate-400 p-2 w-full rounded'
            onChange={(event) =>
              setValues((prev) => ({ ...prev, pass: event.target.value }))
            }
          />
        </div>
        <div className='mt-4 border-b'>
          <a href={values.userid === 'admin' && values.pass === 'admin123' ? '/dashboard' : ''}>
            <button onClick={handleSubmission} className='w-full border mb-6 bg-[#28507B] text-white font-semibold rounded p-2'>
              Login
            </button>
          </a>
        </div>
        <div className='mt-4'>
          <button className='w-full border rounded px-2 py-[6px] border-slate-400 text-slate-600 shadow-lg'>
            If you forget your password, please contact administrator.
          </button>
        </div>
        {
          errorMsg === 'Wrong User ID or Password' ? <div> Wrong email or password </div> : <div> </div>
        }
      </div>
    </div>
  )
}

export default Login
