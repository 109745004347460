import React from 'react'
import Sidebar from '../../components/Sidebar'
import Dashboard from '../../components/Dashboard/Dashboard'
import Navbar from '../../components/Navbar'

import GoogleMapComponent from '../../components/GoogleMap/GoogleMapComponent'

const Home = () => {
    return (
        <div>
            <div className='flex'>
                <div className='w-[15%]'>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <Dashboard />
                    <GoogleMapComponent/>
                </div>
            </div>
        </div>
    )
}

export default Home
