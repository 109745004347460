import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddSiteComponent = () => {
  const [zones, setZones] = useState([]);
  const [circles, setCircles] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedZone, setSelectedZone] = useState('');
  const [selectedCircle, setSelectedCircle] = useState('');
  const [selectedCluster, setSelectedCluster] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [circuitId, setCircuitId] = useState('');
  const [siteName, setSiteName] = useState('');

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchCircles(selectedZone);
    } else {
      setCircles([]);
      setClusters([]);
      setCities([]);
    }
  }, [selectedZone]);

  useEffect(() => {
    if (selectedCircle) {
      fetchClusters(selectedZone, selectedCircle);
    } else {
      setClusters([]);
      setCities([]);
    }
  }, [selectedCircle]);

  useEffect(() => {
    if (selectedCluster) {
      fetchCities(selectedZone, selectedCircle, selectedCluster);
    } else {
      setCities([]);
    }
  }, [selectedCluster]);

  const fetchZones = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/zones.json');
      const data = response.data;
      const loadedZones = [];
      for (const key in data) {
        loadedZones.push({
          id: key,
          name: data[key].name,
        });
      }
      setZones(loadedZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchCircles = async (zoneId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles.json`);
      const data = response.data;
      const loadedCircles = [];
      for (const key in data) {
        loadedCircles.push({
          id: key,
          name: data[key].name,
        });
      }
      setCircles(loadedCircles);
    } catch (error) {
      console.error('Error fetching circles:', error);
    }
  };

  const fetchClusters = async (zoneId, circleId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters.json`);
      const data = response.data;
      const loadedClusters = [];
      for (const key in data) {
        loadedClusters.push({
          id: key,
          name: data[key].name,
        });
      }
      setClusters(loadedClusters);
    } catch (error) {
      console.error('Error fetching clusters:', error);
    }
  };

  const fetchCities = async (zoneId, circleId, clusterId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters/${clusterId}/cities.json`);
      const data = response.data;
      const loadedCities = [];
      for (const key in data) {
        loadedCities.push({
          id: key,
          name: data[key].name,
        });
      }
      setCities(loadedCities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleAddSite = async () => {
    if (selectedZone && selectedCircle && selectedCluster && selectedCity && circuitId && siteName) {
      try {
        const newSite = {
          circuitId,
          siteName,
        };
        await axios.post(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${selectedZone}/circles/${selectedCircle}/clusters/${selectedCluster}/cities/${selectedCity}/sites.json`, newSite);
        alert('Site added successfully!');
      } catch (error) {
        console.error('Error adding site:', error);
        alert('Error adding site!');
      }
    } else {
      alert('Please fill all the details');
    }
  };

  return (
    <div className='bg-slate-50 p-4 h-screen'>
      <div className='bg-white p-4 shadow-xl rounded-xl'>
        <div className='font-semibold text-lg text-[#28507B]'>Add Site Details</div>
        <div className='flex mt-8 gap-6'>
          <div className='w-1/2 '>
            <div className='text-slate-600'>Select Zone</div>
            <select
              value={selectedZone}
              onChange={(e) => setSelectedZone(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            >
              <option value="">Select Zone</option>
              {zones.map(zone => (
                <option key={zone.id} value={zone.id}>{zone.name}</option>
              ))}
            </select>
            <div className='text-xs text-slate-500 font-semibold mt-1'>Select Zone</div>
          </div>
          <div className='w-1/2 '>
            <div className='text-slate-600'>Select Circle</div>
            <select
              value={selectedCircle}
              onChange={(e) => setSelectedCircle(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            >
              <option value="">Select Circle</option>
              {circles.map(circle => (
                <option key={circle.id} value={circle.id}>{circle.name}</option>
              ))}
            </select>
            <div className='text-xs text-slate-500 font-semibold mt-1'>Select Circle</div>
          </div>
        </div>

        <div className='flex mt-6 gap-2 mb-6'>
          <div className='w-1/2 '>
            <div className='text-slate-600'>Select Cluster Name</div>
            <select
              value={selectedCluster}
              onChange={(e) => setSelectedCluster(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            >
              <option value="">Select Cluster</option>
              {clusters.map(cluster => (
                <option key={cluster.id} value={cluster.id}>{cluster.name}</option>
              ))}
            </select>
            <div className='text-xs text-slate-500 font-semibold mt-1'>Select Cluster</div>
          </div>

          <div className='w-1/2'>
            <div className='text-slate-600'>Select City </div>
            <select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            >
              <option value="">Select City</option>
              {cities.map(city => (
                <option key={city.id} value={city.id}>{city.name}</option>
              ))}
            </select>
            <div className='text-xs text-slate-500 font-semibold mt-1'>Select City</div>
          </div>
        </div>
        <div className='flex mt-6 gap-2 mb-6'>
          <div className='w-1/2 '>
            <div className='text-slate-600'>Enter Circuit ID</div>
            <input
              type="text"
              value={circuitId}
              onChange={(e) => setCircuitId(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
              placeholder='Circuit ID'
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Circuit Id Should be Alpha Numeric</div>
          </div>

          <div className='w-1/2'>
            <div className='text-slate-600'>Enter Site Name</div>
            <input
              type="text"
              value={siteName}
              onChange={(e) => setSiteName(e.target.value)}
              placeholder='Site Name'
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Site Name Should be Alpha Numeric</div>
          </div>
        </div>
        <button
          onClick={handleAddSite}
          className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-lg'
        >
          Add Location Details
        </button>
      </div>
    </div>
  );
};

export default AddSiteComponent;