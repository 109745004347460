import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ManageClientComponent = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/clients.json');
      const data = response.data;
      const loadedClients = [];
      for (const key in data) {
        loadedClients.push({
          id: key,
          ...data[key]
        });
      }
      setClients(loadedClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const deleteClient = async (clientId) => {
    try {
      await axios.delete(`https://rachpower-dad00-default-rtdb.firebaseio.com/clients/${clientId}.json`);
      setClients((prevClients) => prevClients.filter(client => client.id !== clientId));
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  return (
    <div className='border p-4 h-screen bg-slate-50'>
      <div className='p-4 border rounded-xl shadow-xl bg-white'>
        <div className='font-semibold text-lg text-[#28507B]'>Manage Clients Details</div>
        <div className='flex mt-8 gap-2'>
          <div className='w-1/3'>
            <input
              type="text"
              className='border border-slate-300 w-full p-2 rounded-lg'
              placeholder='Client Name'
            />
          </div>
          <div className='w-1/3'>
            <input
              type="text"
              className='border border-slate-300 w-full p-2 rounded-lg'
              placeholder='Email'
            />
          </div>
          <div className='w-1/3'>
            <input
              type="text"
              className='border border-slate-300 w-full p-2 rounded-lg'
              placeholder='Contact No.'
            />
          </div>
        </div>
        <div className='flex mt-4 gap-2 mb-4'>
          <div className='w-1/3'>
            <input
              type="date"
              className='border border-slate-300 w-full p-2 rounded-lg text-slate-400'
            />
          </div>
          <div>
            <button className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-md'>
              Search Clients
            </button>
          </div>
        </div>
      </div>

      <div className='mt-6 rounded-xl shadow-xl border bg-white'>
        <div className='border my-6 bg-[#eaecf4]'>
          <div className='grid grid-cols-7 my-4 text-[#6e707e] text-[13px] font-semibold'>
            <div className='text-center text-black'>S No</div>
            <div className='text-center text-black'>Client Name</div>
            <div className='text-center text-black'>Email</div>
            <div className='text-center text-black'>Contact No</div>
            <div className='text-center text-black'>Created By</div>
            <div className='text-center text-black'>Edit</div>
            <div className='text-center text-black'>Delete</div>
          </div>
          {clients.map((client, index) => (
            <div className=' bg-white  py-4 grid grid-cols-7 my-4 text-[#6e707e] text-[13px] font-semibold' key={client.id}>
              <div className='text-center'>{index + 1}</div>
              <div className='text-center'>{client.name}</div>
              <div className='text-center'>{client.email}</div>
              <div className='text-center'>{client.contact}</div>
              <div className='text-center'>Created By Admin</div>
              <div className='text-center'>
                <button className='text-blue-500'>Edit</button>
              </div>
              <div className='text-center'>
                <button className='text-red-500' onClick={() => deleteClient(client.id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageClientComponent;
