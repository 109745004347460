import React, { useState } from 'react';
import axios from 'axios';

const ManageUserComponent = () => {
    const [loginId, setLoginId] = useState('');
    const [userName, setUserName] = useState('');
    const [date, setDate] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/clients.json');
            const clients = response.data;
            const foundUsers = [];

            for (const clientId in clients) {
                const client = clients[clientId];
                if (client.users) {
                    for (const userId in client.users) {
                        const user = client.users[userId];
                        if (
                            (loginId && user.loginId.includes(loginId)) ||
                            (userName && user.userName.includes(userName))
                        ) {
                            foundUsers.push(user);
                        }
                    }
                }
            }
           
            setUsers(foundUsers);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='border p-4 h-screen bg-slate-50'>
            <div className='p-4 border rounded-xl shadow-xl bg-white'>
                <div className='font-semibold text-lg text-[#28507B]'>Manage CMS User Details</div>
                <div className='flex mt-8 gap-2'>
                    <div className='w-1/3'>
                        <input
                            type="text"
                            className='border border-slate-300 w-full p-2 rounded-lg'
                            placeholder='Login ID'
                            value={loginId}
                            onChange={(e) => setLoginId(e.target.value)}
                        />
                    </div>
                    <div className='w-1/3'>
                        <input
                            type="text"
                            className='border border-slate-300 w-full p-2 rounded-lg'
                            placeholder='User Name'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className='w-1/3'>
                        <input
                            type="date"
                            className='border border-slate-300 w-full p-2 rounded-lg text-slate-400'
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className='flex mt-4 gap-2 mb-4'>
                    <button
                        onClick={handleSearch}
                        className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-md'
                        disabled={loading}
                    >
                        {loading ? 'Searching...' : 'Search'}
                    </button>
                </div>
            </div>

            <div className='mt-6 rounded-xl shadow-xl border bg-white'>
                <div className='border my-6 bg-[#eaecf4]'>
                    <div className='grid grid-cols-7 my-4 text-[#6e707e] text-[13px] font-semibold'>
                        <div className='text-center'>S No</div>
                        <div className='text-center'>Name</div>
                        <div className='text-center'>Login ID</div>
                        <div className='text-center'>Password</div>
                        <div className='text-center'>Edit</div>
                        <div className='text-center'>Access</div>
                        <div className='text-center'>Delete</div>
                    </div>
                    <div>
                        {users.map((user, index) => (
                            <div key={index} className='grid grid-cols-7 my-2 text-[#6e707e] text-[13px]'>
                                <div className='text-center'>{index + 1}</div>
                                <div className='text-center'>{user.userName}</div>
                                <div className='text-center'>{user.loginId}</div>
                                <div className='text-center'>{user.loginPassword}</div>
                                <div className='text-center'>
                                    <button className='text-blue-500'>Edit</button>
                                </div>
                                <div className='text-center'>
                                    <button className='text-green-500'>Access</button>
                                </div>
                                <div className='text-center'>
                                    <button className='text-red-500'>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageUserComponent;