import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageClusterComponent = () => {
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');
  const [circles, setCircles] = useState([]);
  const [selectedCircle, setSelectedCircle] = useState('');
  const [clusters, setClusters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchCircles(selectedZone);
    }
  }, [selectedZone]);

  useEffect(() => {
    if (selectedZone && selectedCircle) {
      fetchClusters(selectedZone, selectedCircle);
    }
  }, [selectedZone, selectedCircle]);

  const fetchZones = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/zones.json');
      const data = response.data;
      const loadedZones = [];
      for (const key in data) {
        loadedZones.push({
          id: key,
          ...data[key]
        });
      }
      setZones(loadedZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchCircles = async (zoneId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles.json`);
      const data = response.data;
      const loadedCircles = [];
      for (const key in data) {
        loadedCircles.push({
          id: key,
          ...data[key]
        });
      }
      setCircles(loadedCircles);
    } catch (error) {
      console.error('Error fetching circles:', error);
    }
  };

  const fetchClusters = async (zoneId, circleId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters.json`);
      const data = response.data;
      const loadedClusters = [];
      for (const key in data) {
        loadedClusters.push({
          id: key,
          ...data[key]
        });
      }
      setClusters(loadedClusters);
    } catch (error) {
      console.error('Error fetching clusters:', error);
    }
  };

  const handleDeleteCluster = async (zoneId, circleId, clusterId) => {
    try {
      await axios.delete(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters/${clusterId}.json`);
      alert('Cluster deleted successfully');
      fetchClusters(zoneId, circleId); // Refresh the list of clusters
    } catch (error) {
      console.error('Error deleting cluster:', error);
      alert('Error deleting cluster');
    }
  };

  const filteredClusters = clusters.filter(cluster =>
    cluster.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='border p-4 h-screen bg-slate-50'>
      <div className='p-4 border rounded-xl shadow-xl bg-white'>
        <div className='font-semibold text-lg text-[#28507B]'>Manage Clusters Details</div>
        <div className='flex mt-8 gap-2'>
          <div className='w-1/3'>
            <select
              value={selectedZone}
              onChange={(e) => setSelectedZone(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Zone</option>
              {zones.map(zone => (
                <option key={zone.id} value={zone.id}>{zone.name}</option>
              ))}
            </select>
          </div>
          <div className='w-1/3'>
            <select
              value={selectedCircle}
              onChange={(e) => setSelectedCircle(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Circle</option>
              {circles.map(circle => (
                <option key={circle.id} value={circle.id}>{circle.name}</option>
              ))}
            </select>
          </div>
          <div className='w-1/3'>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
              placeholder='Cluster Name'
            />
          </div>
        </div>
        <div className='flex mt-4 gap-2 mb-4'>
          <div className='w-1/3'>
            <input
              type="date"
              className='border border-slate-300 w-full p-2 rounded-lg text-slate-400'
            />
          </div>
          <div>
            <button className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-md'>
              Search Cluster
            </button>
          </div>
        </div>
      </div>

      <div className='mt-6 rounded-xl shadow-xl border bg-white'>
        <div className='border my-6 bg-[#eaecf4]'>
          <div className='grid grid-cols-7 my-4 text-[#6e707e] text-[13px] font-semibold'>
            <div className='text-center'>S No</div>
            <div className='text-center'>Zone</div>
            <div className='text-center'>Circle</div>
            <div className='text-center'>Cluster</div>
            <div className='text-center'>Created By</div>
            <div className='text-center'>Edit</div>
            <div className='text-center'>Delete</div>
          </div>
        </div>
        <div>
          {filteredClusters.map((cluster, index) => (
            <div key={cluster.id} className='grid grid-cols-7 my-4 text-center text-[13px]'>
              <div>{index + 1}</div>
              <div>{zones.find(zone => zone.id === selectedZone)?.name}</div>
              <div>{circles.find(circle => circle.id === selectedCircle)?.name}</div>
              <div>{cluster.name}</div>
              <div>{cluster.createdBy}</div>
              <div>
                <button className='text-blue-500'>Edit</button>
              </div>
              <div>
                <button className='text-red-500' onClick={() => handleDeleteCluster(selectedZone, selectedCircle, cluster.id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageClusterComponent;
