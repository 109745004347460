import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import ZoneCircleComponent from '../../components/ZoneCircleComponent/ZoneCircleComponent'

const ZoneCircle = () => {
    return (
        <div>
            <div className='flex'>
                <div className='w-[15%]'>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <ZoneCircleComponent/>
                </div>
            </div>
        </div>
    )
}

export default ZoneCircle
