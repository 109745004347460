import React from 'react'

const Navbar = () => {
    return (
        <div className='flex justify-between border py-[26px] w-[175vh] bg-blue-900 text-white'>
            <div className='text-2xl ml-6'>
                ☰
            </div>
            <div className='flex gap-2 mr-6'>
                <div className='bg-blue-900 rounded-full'>
                    <img className='border border-white rounded-full' width={30} src="https://ripl.org.in/TMS/img/boy.png" alt="" />
                </div>
                <div className='my-auto'> 
                    System Administrator
                </div>
            </div>
        </div>
    )
}

export default Navbar
