import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageSiteComponent = () => {
  const [zones, setZones] = useState([]);
  const [circles, setCircles] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [cities, setCities] = useState([]);
  const [sites, setSites] = useState([]);

  const [selectedZone, setSelectedZone] = useState('');
  const [selectedCircle, setSelectedCircle] = useState('');
  const [selectedCluster, setSelectedCluster] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [searchCircuitId, setSearchCircuitId] = useState('');
  const [searchSiteName, setSearchSiteName] = useState('');
  const [filteredSites, setFilteredSites] = useState([]);

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchCircles(selectedZone);
    } else {
      setCircles([]);
      setClusters([]);
      setCities([]);
      setSites([]);
    }
  }, [selectedZone]);

  useEffect(() => {
    if (selectedCircle) {
      fetchClusters(selectedZone, selectedCircle);
    } else {
      setClusters([]);
      setCities([]);
      setSites([]);
    }
  }, [selectedCircle]);

  useEffect(() => {
    if (selectedCluster) {
      fetchCities(selectedZone, selectedCircle, selectedCluster);
    } else {
      setCities([]);
      setSites([]);
    }
  }, [selectedCluster]);

  useEffect(() => {
    if (selectedCity) {
      fetchSites(selectedZone, selectedCircle, selectedCluster, selectedCity);
    } else {
      setSites([]);
    }
  }, [selectedCity]);

  const fetchZones = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/zones.json');
      const data = response.data;
      const loadedZones = [];
      for (const key in data) {
        loadedZones.push({
          id: key,
          name: data[key].name,
        });
      }
      setZones(loadedZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchCircles = async (zoneId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles.json`);
      const data = response.data;
      const loadedCircles = [];
      for (const key in data) {
        loadedCircles.push({
          id: key,
          name: data[key].name,
        });
      }
      setCircles(loadedCircles);
    } catch (error) {
      console.error('Error fetching circles:', error);
    }
  };

  const fetchClusters = async (zoneId, circleId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters.json`);
      const data = response.data;
      const loadedClusters = [];
      for (const key in data) {
        loadedClusters.push({
          id: key,
          name: data[key].name,
        });
      }
      setClusters(loadedClusters);
    } catch (error) {
      console.error('Error fetching clusters:', error);
    }
  };

  const fetchCities = async (zoneId, circleId, clusterId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters/${clusterId}/cities.json`);
      const data = response.data;
      const loadedCities = [];
      for (const key in data) {
        loadedCities.push({
          id: key,
          name: data[key].name,
        });
      }
      setCities(loadedCities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchSites = async (zoneId, circleId, clusterId, cityId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles/${circleId}/clusters/${clusterId}/cities/${cityId}/sites.json`);
      const data = response.data;
      const loadedSites = [];
      for (const key in data) {
        loadedSites.push({
          id: key,
          ...data[key],
        });
      }
      setSites(loadedSites);
      setFilteredSites(loadedSites);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  const handleSearch = () => {
    let filtered = sites;
    if (searchCircuitId) {
      filtered = filtered.filter(site => site.circuitId.includes(searchCircuitId));
    }
    if (searchSiteName) {
      filtered = filtered.filter(site => site.siteName.includes(searchSiteName));
    }
    setFilteredSites(filtered);
  };

  return (
    <div className='border p-4 h-screen bg-slate-50'>
      <div className='p-4 border rounded-xl shadow-xl bg-white'>
        <div className='font-semibold text-lg text-[#28507B]'>Manage Sites Details</div>
        <div className='flex mt-8 gap-2'>
          <div className='w-1/3'>
            <select
              value={selectedZone}
              onChange={(e) => setSelectedZone(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Zone</option>
              {zones.map(zone => (
                <option key={zone.id} value={zone.id}>{zone.name}</option>
              ))}
            </select>
          </div>
          <div className='w-1/3'>
            <select
              value={selectedCircle}
              onChange={(e) => setSelectedCircle(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Circle</option>
              {circles.map(circle => (
                <option key={circle.id} value={circle.id}>{circle.name}</option>
              ))}
            </select>
          </div>
          <div className='w-1/3'>
            <select
              value={selectedCluster}
              onChange={(e) => setSelectedCluster(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select Cluster</option>
              {clusters.map(cluster => (
                <option key={cluster.id} value={cluster.id}>{cluster.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex mt-4 gap-2 mb-4'>
          <div className='w-1/3'>
            <select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
            >
              <option value="">Select City</option>
              {cities.map(city => (
                <option key={city.id} value={city.id}>{city.name}</option>
              ))}
            </select>
          </div>
          <div className='w-1/3'>
            <input
              type="text"
              value={searchCircuitId}
              onChange={(e) => setSearchCircuitId(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
              placeholder='Circuit ID'
            />
          </div>
          <div className='w-1/3'>
            <input
              type="text"
              value={searchSiteName}
              onChange={(e) => setSearchSiteName(e.target.value)}
              className='border border-slate-300 w-full p-2 rounded-lg'
              placeholder='Site Name'
            />
          </div>
        </div>
        <div className='flex mt-4 gap-2 mb-4'>
          <div className='w-1/3'>
            <input
              type="date"
              className='border border-slate-300 w-full p-2 rounded-lg text-slate-400'
            />
          </div>
          <button
            onClick={handleSearch}
            className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-md'
          >
            Search Sites
          </button>
        </div>
      </div>

      <div className='mt-6 rounded-xl shadow-xl border bg-white'>
        <div className='border my-6 bg-[#eaecf4]'>
          <div className='grid grid-cols-10 my-4 text-[#6e707e] text-[13px] font-semibold'>
            <div className='text-center'>S No</div>
            <div className='text-center'>Zone</div>
            <div className='text-center'>Circle</div>
            <div className='text-center'>Cluster</div>
            <div className='text-center'>City</div>
            <div className='text-center'>Circuit ID</div>
            <div className='text-center'>Site</div>
            <div className='text-center'>Created By</div>
            <div className='text-center'>Edit</div>
            <div className='text-center'>Delete</div>
          </div>
          {filteredSites.map((site, index) => (
            <div key={site.id} className='grid grid-cols-10 my-4 text-center'>
              <div>{index + 1}</div>
              <div>{zones.find(z => z.id === selectedZone)?.name || ''}</div>
              <div>{circles.find(c => c.id === selectedCircle)?.name || ''}</div>
              <div>{clusters.find(c => c.id === selectedCluster)?.name || ''}</div>
              <div>{cities.find(c => c.id === selectedCity)?.name || ''}</div>
              <div>{site.circuitId}</div>
              <div>{site.siteName}</div>
              <div>{/* Created By - Add logic to fetch created by user */}</div>
              <div>
                <button className='text-blue-500'>Edit</button>
              </div>
              <div>
                <button className='text-red-500'>Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ManageSiteComponent;
