import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const mapStyles = {
  width: '100%',
  height: '100%',
};

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '580px',
};

const GoogleMapComponent = () => {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await fetch("https://rachpower-dad00-default-rtdb.firebaseio.com/psuDevices.json");
        if (response.ok) {
          const data = await response.json();
          const fetchedMarkers = Object.entries(data).map(([key, device]) => ({
            id: key,
            lat: parseFloat(device.latitude),
            lng: parseFloat(device.longitude),
            ...device
          }));
          setMarkers(fetchedMarkers);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCoordinates();
  }, []);

  return (
    <div style={containerStyle} className='p-6 bg-slate-50'>
      <LoadScript googleMapsApiKey="">
        <GoogleMap
          mapContainerStyle={mapStyles}
          center={markers.length > 0 ? { lat: markers[1].lng, lng: markers[1].lat } : { lat: 0, lng: 0 }}
          zoom={4.3}
        >
          {markers.map(marker => (
            <Marker
              key={marker.id}
              position={{ lat: marker.lng, lng: marker.lat }}
              onClick={() => setSelectedMarker(marker)}
            />
          ))}
          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lng, lng: selectedMarker.lat }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <h2>{selectedMarker.client}</h2>
                <p><strong>Project:</strong> {selectedMarker.project}</p>
                <p><strong>Site:</strong> {selectedMarker.site}</p>
                <p><strong>City:</strong> {selectedMarker.city}</p>
                <p><strong>Cluster:</strong> {selectedMarker.cluster}</p>
                <p><strong>Zone:</strong> {selectedMarker.zone}</p>
                <p><strong>AC Voltage High:</strong> {selectedMarker.acVoltageHigh}</p>
                <p><strong>AC Voltage Low:</strong> {selectedMarker.acVoltageLow}</p>
                <p><strong>DC Voltage High:</strong> {selectedMarker.dcVoltageHigh}</p>
                <p><strong>DC Voltage Low:</strong> {selectedMarker.dcVoltageLow}</p>
                <p><strong>Earth Voltage High:</strong> {selectedMarker.earthVoltageHigh}</p>
                <p><strong>Earth Voltage Low:</strong> {selectedMarker.earthVoltageLow}</p>
                <p><strong>Temperature High:</strong> {selectedMarker.tempHigh}</p>
                <p><strong>Temperature Low:</strong> {selectedMarker.tempLow}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default GoogleMapComponent;
