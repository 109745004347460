import React from 'react'

const FaultReportComponent = () => {
  return (
    <div className='border p-4 h-screen bg-slate-50'>
            <div className='p-4 border rounded-xl shadow-xl bg-white'>
                <div className='font-semibold text-lg text-[#28507B]'>PSU Fault Reports</div>
                <div className='flex mt-8 gap-2'>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="">Select Client</option>
                            <option value="">Client 1</option>
                        </select>
                    </div>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="">Select Project</option>
                            <option value="">Project 1</option>
                        </select>
                    </div>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="">Select Zone</option>
                            <option value="">Zone 1</option>
                        </select>
                    </div>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="">Select Circle</option>
                            <option value="">Circle 1</option>
                        </select>
                    </div>
                </div>
                <div className='flex mt-4 gap-2 mb-4'>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="">Select Cluster</option>
                            <option value="">Cluster 1</option>
                        </select>
                    </div>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="">Select City</option>
                            <option value="">City 1</option>
                        </select>
                    </div>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="">Select Site</option>
                            <option value="">Site 1</option>
                        </select>
                    </div>
                    <div className='w-1/4'>
                        <input
                            type="text"
                            className='border border-slate-300 w-full p-2 rounded-lg'
                            placeholder='Enter Site ID'
                        />
                    </div>
                </div>

                <div className='flex mt-4 gap-2 mb-4'>
                    <div className='w-1/4'>
                        <input
                            type="text"
                            className='border border-slate-300 w-full p-2 rounded-lg'
                            placeholder='Enter IMEI No'
                        />
                    </div>
                    <div className='w-1/4'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg'>
                            <option value="" >Select Fault Type</option>
                        </select>
                    </div>
                    <div className='w-1/4'>
                        <input
                            type="date"
                            className='border border-slate-300 w-full p-2 rounded-lg'
                        />
                        <div className='text-sm text-slate-500'>From Date</div>
                    </div>
                    <div className='w-1/4'>
                        <input
                            type='date'
                            className='border border-slate-300 w-full p-2 rounded-lg'
                        />
                        <div className='text-sm text-slate-500'>Start Date</div>
                    </div>


                </div>
                <button className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-md'>
                    Seacrh
                </button>

            </div>

            <div className='mt-6 rounded-xl shadow-xl border bg-white'>
                <div className='border my-6 bg-[rgb(234,236,244)]'>
                    <div className='flex gap-12 ml-6 my-4 text-[#6e707e] text-[13px] font-semibold'>
                        <div className='text-center'>S No</div>
                        <div className='text-center'>Client</div>
                        <div className='text-center'>Project</div>
                        <div className='text-center'>Zone</div>
                        <div className='text-center'>Circle</div>
                        <div className='text-center'>Cluster</div>
                        <div className='text-center'>City</div>
                        <div className='text-center'>Site</div>
                        <div className='text-center'>Siteld</div>
                        <div className='text-center'>IMEI No</div>
                        <div className='text-center'>Fault Type</div>
                        <div className='text-center'>Value</div>
                        <div className='text-center'>Fault Date</div>
                        <div className='text-center'>Reset Date</div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default FaultReportComponent
