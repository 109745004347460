import React, { useState } from 'react';
import axios from 'axios';

const AddClientComponent = () => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientContact, setClientContact] = useState('');

  const handleAddClient = async () => {
    const clientDetails = {
      name: clientName,
      email: clientEmail,
      contact: clientContact,
    };

    try {
      await axios.post('https://rachpower-dad00-default-rtdb.firebaseio.com/clients.json', clientDetails);
      alert('Client added successfully');
      // Reset form fields
      setClientName('');
      setClientEmail('');
      setClientContact('');
    } catch (error) {
      console.error('Error adding client:', error);
      alert('Error adding client');
    }
  };

  return (
    <div className='bg-slate-50 p-4 h-screen'>
      <div className='bg-white p-4 shadow-xl rounded-xl'>
        <div className='font-semibold text-lg text-[#28507B]'>Add Client Details</div>
        <div className='flex mt-8 gap-6'>
          <div className='w-1/2'>
            <div className='text-slate-600'>Enter Client Name</div>
            <input
              type="text"
              className='border w-full p-2 rounded-lg mt-2'
              placeholder='Client Name'
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Client Name up to 200 characters</div>
          </div>
          <div className='w-1/2'>
            <div className='text-slate-600'>Enter Client Email</div>
            <input
              type="text"
              className='border w-full p-2 rounded-lg mt-2'
              placeholder='Client Email'
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Enter Valid Email ID</div>
          </div>
        </div>

        <div className='flex mt-6 gap-2 mb-6'>
          <div className='w-1/2'>
            <div className='text-slate-600'>Enter Client Contact Number</div>
            <input
              type="text"
              className='border w-full p-2 rounded-lg mt-2'
              placeholder='Client Contact Number'
              value={clientContact}
              onChange={(e) => setClientContact(e.target.value)}
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Contact Number in digit only</div>
          </div>
          <button
            className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-lg'
            onClick={handleAddClient}
          >
            Add Client Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClientComponent;
