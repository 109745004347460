import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddProjectComponent = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectDetails, setProjectDetails] = useState('');

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/clients.json');
      const data = response.data;
      const loadedClients = [];
      for (const key in data) {
        loadedClients.push({
          id: key,
          name: data[key].name
        });
      }
      setClients(loadedClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleAddProject = async () => {
    if (!selectedClient || !projectName || !projectDetails) {
      alert('Please fill out all fields');
      return;
    }

    const project = {
      name: projectName,
      details: projectDetails,
    };

    try {
      await axios.post(`https://rachpower-dad00-default-rtdb.firebaseio.com/clients/${selectedClient}/projects.json`, project);
      alert('Project added successfully');
      // Reset form fields
      setSelectedClient('');
      setProjectName('');
      setProjectDetails('');
    } catch (error) {
      console.error('Error adding project:', error);
      alert('Error adding project');
    }
  };

  return (
    <div className='bg-slate-50 p-4 h-screen'>
      <div className='bg-white p-4 shadow-xl rounded-xl'>
        <div className='font-semibold text-lg text-[#28507B]'>Add Project Details</div>
        <div className='flex mt-8 gap-6'>
          <div className='w-1/2'>
            <div className='text-slate-600'>Select Client</div>
            <select
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            >
              <option value="">Select Client</option>
              {clients.map(client => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
            <div className='text-xs text-slate-500 font-semibold mt-1'>Select Client for Project services</div>
          </div>
          <div className='w-1/2'>
            <div className='text-slate-600'>Enter Project Name</div>
            <input
              type="text"
              className='border w-full p-2 rounded-lg mt-2'
              placeholder='Project Name'
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Project Name Should be Alpha Numeric</div>
          </div>
        </div>

        <div className='flex mt-6 gap-2 mb-6'>
          <div className='w-1/2'>
            <div className='text-slate-600'>Enter Project Details</div>
            <input
              type="text"
              className='border w-full p-2 rounded-lg mt-2'
              placeholder='Project Details'
              value={projectDetails}
              onChange={(e) => setProjectDetails(e.target.value)}
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Project Details to describe project</div>
          </div>
          <button
            className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-lg'
            onClick={handleAddProject}
          >
            Add Project Details
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddProjectComponent;
