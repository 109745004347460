import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddClusterComponent = () => {
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');
  const [circles, setCircles] = useState([]);
  const [selectedCircle, setSelectedCircle] = useState('');
  const [clusterName, setClusterName] = useState('');

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedZone) {
      fetchCircles(selectedZone);
    }
  }, [selectedZone]);

  const fetchZones = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/zones.json');
      const data = response.data;
      const loadedZones = [];
      for (const key in data) {
        loadedZones.push({
          id: key,
          ...data[key]
        });
      }
      setZones(loadedZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const fetchCircles = async (zoneId) => {
    try {
      const response = await axios.get(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${zoneId}/circles.json`);
      const data = response.data;
      const loadedCircles = [];
      for (const key in data) {
        loadedCircles.push({
          id: key,
          ...data[key]
        });
      }
      setCircles(loadedCircles);
    } catch (error) {
      console.error('Error fetching circles:', error);
    }
  };

  const handleAddCluster = async () => {
    if (!selectedZone || !selectedCircle || !clusterName) {
      alert('Please fill out all fields');
      return;
    }

    const cluster = {
      name: clusterName
    };

    try {
      await axios.post(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${selectedZone}/circles/${selectedCircle}/clusters.json`, cluster);
      alert('Cluster added successfully');
      // Reset form fields
      setSelectedZone('');
      setSelectedCircle('');
      setClusterName('');
    } catch (error) {
      console.error('Error adding cluster:', error);
      alert('Error adding cluster');
    }
  };

  return (
    <div className='bg-slate-50 p-4 h-screen'>
      <div className='bg-white p-4 shadow-xl rounded-xl'>
        <div className='font-semibold text-lg text-[#28507B]'>Add Cluster Details</div>
        <div className='flex mt-8 gap-6'>
          <div className='w-1/2'>
            <div className='text-slate-600'>Select Zone</div>
            <select
              value={selectedZone}
              onChange={(e) => setSelectedZone(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            >
              <option value="">Select Zone</option>
              {zones.map(zone => (
                <option key={zone.id} value={zone.id}>{zone.name}</option>
              ))}
            </select>
            <div className='text-xs text-slate-500 font-semibold mt-1'>Select Zone</div>
          </div>
          <div className='w-1/2'>
            <div className='text-slate-600'>Select Circle</div>
            <select
              value={selectedCircle}
              onChange={(e) => setSelectedCircle(e.target.value)}
              className='border w-full p-2 rounded-lg mt-2 text-slate-600'
            >
              <option value="">Select Circle</option>
              {circles.map(circle => (
                <option key={circle.id} value={circle.id}>{circle.name}</option>
              ))}
            </select>
            <div className='text-xs text-slate-500 font-semibold mt-1'>Select Circle</div>
          </div>
        </div>

        <div className='flex mt-6 gap-2 mb-6'>
          <div className='w-1/2'>
            <div className='text-slate-600'>Enter Cluster Name</div>
            <input
              type="text"
              className='border w-full p-2 rounded-lg mt-2'
              placeholder='Cluster Name'
              value={clusterName}
              onChange={(e) => setClusterName(e.target.value)}
            />
            <div className='text-xs text-slate-500 font-semibold mt-1'>Cluster Name Should be Alpha Numeric</div>
          </div>
          <button
            className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-lg'
            onClick={handleAddCluster}
          >
            Add Cluster Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClusterComponent;
