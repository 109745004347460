import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import AddSiteComponent from '../../components/AddSiteComponent/AddSiteComponent'

const AddSite = () => {
  return (
    <div>
            <div className='flex'>
                <div className='w-[15%]'>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <AddSiteComponent/>
                </div>
            </div>
        </div>
  )
}

export default AddSite
