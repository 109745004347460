import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ZoneCircleComponent = () => {
  const [zones, setZones] = useState([]);
  const [zoneName, setZoneName] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [circleName, setCircleName] = useState('');

  useEffect(() => {
    fetchZones();
  }, []);

  const fetchZones = async () => {
    try {
      const response = await axios.get('https://rachpower-dad00-default-rtdb.firebaseio.com/zones.json');
      const data = response.data;
      const loadedZones = [];
      for (const key in data) {
        loadedZones.push({
          id: key,
          name: data[key].name,
          circles: data[key].circles ? Object.entries(data[key].circles).map(([id, circle]) => ({ id, ...circle })) : []
        });
      }
      setZones(loadedZones);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const handleAddZone = async () => {
    if (!zoneName) {
      alert('Please enter a zone name');
      return;
    }

    try {
      await axios.post('https://rachpower-dad00-default-rtdb.firebaseio.com/zones.json', { name: zoneName });
      alert('Zone added successfully');
      setZoneName('');
      fetchZones();
    } catch (error) {
      console.error('Error adding zone:', error);
      alert('Error adding zone');
    }
  };

  const handleAddCircle = async () => {
    if (!selectedZone || !circleName) {
      alert('Please fill out all fields');
      return;
    }

    try {
      await axios.post(`https://rachpower-dad00-default-rtdb.firebaseio.com/zones/${selectedZone}/circles.json`, { name: circleName });
      alert('Circle added successfully');
      setSelectedZone('');
      setCircleName('');
      fetchZones();
    } catch (error) {
      console.error('Error adding circle:', error);
      alert('Error adding circle');
    }
  };

  return (
    <div className='bg-slate-50 p-4 h-screen'>
      <div className='bg-white p-4 shadow-xl rounded-xl'>
        <div className='font-semibold text-lg text-[#28507B]'>Add Zone</div>
        <div className='mt-4'>
          <input
            type="text"
            className='border w-full p-2 rounded-lg mt-2'
            placeholder='Zone Name'
            value={zoneName}
            onChange={(e) => setZoneName(e.target.value)}
          />
          <button
            className='text-white bg-[#28507B] my-2 px-3 py-2 rounded-lg'
            onClick={handleAddZone}
          >
            Add Zone
          </button>
        </div>
      </div>
      <div className='bg-white p-4 shadow-xl rounded-xl mt-6'>
        <div className='font-semibold text-lg text-[#28507B]'>Add Circle</div>
        <div className='mt-4'>
          <select
            value={selectedZone}
            onChange={(e) => setSelectedZone(e.target.value)}
            className='border w-full p-2 rounded-lg mt-2 text-slate-600'
          >
            <option value="">Select Zone</option>
            {zones.map(zone => (
              <option key={zone.id} value={zone.id}>{zone.name}</option>
            ))}
          </select>
          <input
            type="text"
            className='border w-full p-2 rounded-lg mt-2'
            placeholder='Circle Name'
            value={circleName}
            onChange={(e) => setCircleName(e.target.value)}
          />
          <button
            className='text-white bg-[#28507B] my-2 px-3 py-2 rounded-lg'
            onClick={handleAddCircle}
          >
            Add Circle
          </button>
        </div>
      </div>
      <div className='bg-white p-4 shadow-xl rounded-xl mt-6'>
        <div className='font-semibold text-lg text-[#28507B]'>Zones and Circles</div>
        <div className='mt-4'>
          <table className='w-full border-collapse'>
            <thead>
              <tr className='bg-[#28507B] text-white'>
                <th className='border p-2'>Zone Name</th>
                <th className='border p-2'>Circles</th>
              </tr>
            </thead>
            <tbody>
              {zones.map(zone => (
                <tr key={zone.id}>
                  <td className='border p-2'>{zone.name}</td>
                  <td className='border p-2'>
                    <ul>
                      {zone.circles.map(circle => (
                        <li key={circle.id}>{circle.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ZoneCircleComponent;
