import React from 'react'

const ChangePasswordComponent = () => {
    return (
        <div className='border p-7'>
            <div className='border p-4'>
                <div className='text-[#28507B] font-semibold'>Change Password</div>
                <div className='mt-6'>
                    <div>
                        <input
                            type="text"
                            className='border'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordComponent
