import React from 'react'

const ManageProjectComponent = () => {
    return (
        <div className=' p-4 h-screen bg-slate-50'>
            <div className='p-4 rounded-xl shadow-xl bg-white'>
                <div className='font-semibold text-lg text-[#28507B]'>Manage Project Details</div>
                <div className='flex mt-8 gap-2'>
                    <div className='w-1/3'>
                        <select name="" id="" className='border border-slate-300 w-full p-2 rounded-lg text-slate-600'>
                            <option value="">Select Client</option>
                            <option value="">Client 1</option>
                            <option value="">Client 2</option>
                        </select>
                    </div>
                    <div className='w-1/3'>
                        <input
                            type="text"
                            className='border border-slate-300 w-full p-2 rounded-lg'
                            placeholder='Project Name'
                        />
                    </div>
                    <div className='w-1/3'>
                        <input
                            type="date"
                            className='border border-slate-300 w-full p-2 rounded-lg text-slate-400'
                        />
                    </div>
                </div>
                <div className='flex mt-4 gap-2 mb-4'>
                    <div>
                        <button className='text-white bg-[#28507B] my-auto px-3 py-2 rounded-md'>
                            Seacrh Projects
                        </button>
                    </div>
                </div>
            </div>

            <div className='mt-6 rounded-xl shadow-xl border bg-white'>
                <div className='border my-6 bg-[#eaecf4]'>
                    <div className='grid grid-cols-8 my-4  text-[#6e707e] text-[13px] font-semibold'>
                        <div className='text-center'>S No</div>
                        <div className='text-center'>Client</div>
                        <div className='text-center'>Project Name</div>
                        <div className='text-center'>Detail</div>
                        <div className='text-center'>No of PSU</div>
                        <div className='text-center'>Created By</div>
                        <div className='text-center'>Edit</div>
                        <div className='text-center'>Delete</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageProjectComponent
