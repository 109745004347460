import React from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import ChangePasswordComponent from '../../components/ChangePasswordComponent/ChangePasswordComponent'

const ChangePassword = () => {
    return (
        <div>
            <div className='flex'>
                <div className='w-[15%]'>
                    <Sidebar />
                </div>
                <div>
                    <Navbar />
                    <ChangePasswordComponent/>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
