import logo from './logo.svg';
import './App.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './components/Login/Login';
import AddClient from './pages/Client/AddClient';
import ManageClient from './pages/Client/ManageClient';
import AddProject from './pages/Projects/AddProject';
import ManageProjects from './pages/Projects/ManageProjects';
import AddCluster from './pages/Cluster/AddCluster';
import ManageCluster from './pages/Cluster/ManageCluster';
import AddCity from './pages/City/AddCity';
import ManageCity from './pages/City/ManageCity';
import AddSite from './pages/Sites/AddSite';
import ManageSite from './pages/Sites/ManageSite';
import AddPSUDevice from './pages/PSU/AddPSUDevice';
import AddPSUAccess from './pages/PSU/AddPSUAccess';
import RevokePSUAccess from './pages/PSU/RevokePSUAccess';
import ManagePSUDevice from './pages/PSU/ManagePSUDevice';
import ManageUser from './pages/Users/ManageUser';
import AddUser from './pages/Users/AddUser';
import LogsReport from './pages/Reports/LogsReport';
import FaultReport from './pages/Reports/FaultReport';
import ACFailReport from './pages/Reports/ACFailReport';
import SiteGraphReport from './pages/Reports/SiteGraphReport';
import SiteLogsExport from './pages/Reports/SiteLogsExport';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import ZoneCircle from './pages/ZoneCircle/ZoneCircle';
import DCVoltageLeak from './pages/DCVoltageLeak/DCVoltageLeak';
import EarthingFault from './pages/EarthingFault/EarthingFault';
import TemperatureFault from './pages/TemperatureFault/TemperatureFault';
import EthernetFault from './pages/EthernetFault/EthernetFault';
import BatteryFaultComponent from './components/BatteryFaultComponent/BatteryFaultComponent';
import BatteryFault from './pages/BatteryFault/BatteryFault';
import WeakSignal from './pages/WeakSignal/WeakSignal';
import HeartbeatFail from './pages/HeartbeatFail/HeartbeatFail';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/dashboard" element={<Home/>}/>
        <Route path='/client/addclient' element={<AddClient/>}/>
        <Route path='/client/manageclient' element={<ManageClient/>}/>
        <Route path='/project/addproject' element={<AddProject/>}/>
        <Route path='/project/manageproject' element={<ManageProjects/>}/>
        <Route path='/cluster/addcluster' element={<AddCluster/>}/>
        <Route path='/cluster/managecluster' element={<ManageCluster/>}/>
        <Route path='/city/addcity' element={<AddCity/>}/>
        <Route path='/city/managecity' element={<ManageCity/>}/>
        <Route path='/site/addsite' element={<AddSite/>}/>
        <Route path='/site/managesite' element={<ManageSite/>}/>
        {/* <Route path='/site/managesite' element={<ManageSite/>}/> */}
        <Route path='/psu/addpsu' element={<AddPSUDevice/>}/>
        <Route path='/psu/managepsu' element={<ManagePSUDevice/>}/>
        <Route path='/psu/addpsuaccess' element={<AddPSUAccess/>}/>
        <Route path='/psu/revokepsu' element={<RevokePSUAccess/>}/>
        <Route path='/user/manageuser' element={<ManageUser/>}/>
        <Route path='/user/adduser' element={<AddUser/>}/>
        <Route path='/report/logreport' element={<LogsReport/>}/>
        <Route path='/report/faultreport' element={<FaultReport/>}/>
        <Route path='/report/acfailreport' element={<ACFailReport/>}/>
        <Route path='/report/sitegraphreport' element={<SiteGraphReport/>}/>
        <Route path='/report/sitelogsexport' element={<SiteLogsExport/>}/>
        <Route path='/changepassword' element={<ChangePassword/>}/>
        <Route path='/zonecircle' element={<ZoneCircle/>}/>
        <Route path='/dcvoltageleak' element={<DCVoltageLeak/>}/>
        <Route path='/earthingfault' element={<EarthingFault/>}/>
        <Route path='/temperaturefault' element={<TemperatureFault/>}/>
        <Route path='/ethernetfault' element={<EthernetFault/>}/>
        <Route path='/batteryfault' element={<BatteryFault/>}/>
        <Route path='/weaksignal' element={<WeakSignal/>}/>
        <Route path='/heartbeatfail' element={<HeartbeatFail/>}/>
      </Routes>
    </Router>
  );
}

export default App;
