import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink for navigation
import logo from './ripllogo.png';

const Sidebar = () => {
    const [showDropdown, setShowDropdown] = useState('');

    const handleItemClick = (menuItem) => {
        if (showDropdown === menuItem) {
            setShowDropdown(''); // Close dropdown if already open
        } else {
            setShowDropdown(menuItem); // Open dropdown for the clicked menu item
        }
    };

    const menuItems = [
        { name: 'Dashboard', dropdownItems: [], path: '/dashboard' },
        { name: 'Client', dropdownItems: ['Add Client', 'Manage Client'] },
        { name: 'Projects', dropdownItems: ['Add Project', 'Manage Projects'] },
        { name: 'Cluster', dropdownItems: ['Add Cluster', 'Manage Clusters'] },
        { name: 'Cities', dropdownItems: ['Add City', 'Manage Cities'] },
        { name: 'Sites', dropdownItems: ['Add Site', 'Manage Sites'] },
        { name: 'PSU Devices', dropdownItems: ['Add PSU Device', 'Manage PSU Device', 'Add PSU Access', 'Revoke PSU Access'] },
        { name: 'Users', dropdownItems: ['Add User', 'Manage Users'] },
        { name: 'Reports', dropdownItems: ['Logs Report', 'Fault Reports', 'AC Fail Report', 'Site Graph Report', 'Site Logs Export'] },
        { name: 'Password', dropdownItems: [] },
        { name: 'Logout', dropdownItems: [], path: '/' }
    ];

    // Define navigation paths (replace with actual paths)
    const navigationPaths = {
        'Add Client': '/client/addclient',
        'Manage Client': '/client/manageclient',
        'Add Project': '/project/addproject',
        'Manage Projects': '/project/manageproject',
        'Add Cluster': '/cluster/addcluster',
        'Manage Clusters': '/cluster/managecluster',
        'Add City': '/city/addcity',
        'Manage Cities': '/city/managecity',
        'Add Site': '/site/addsite',
        'Manage Sites': '/site/managesite',
        'Add PSU Device': '/psu/addpsu',
        'Manage PSU Device': '/psu/managepsu',
        'Add PSU Access': '/psu/addpsuaccess',
        'Revoke PSU Access': '/psu/revokepsu',
        'Add User':'/user/adduser',
        'Manage Users': '/user/manageuser',
        'Logs Report': '/report/logreport',
        'Fault Reports': '/report/faultreport',
        'AC Fail Report': '/report/acfailreport',
        'Site Graph Report': '/report/sitegraphreport',
        'Site Logs Export': '/report/sitelogsexport',
        'Logout':'/'
        // Add more paths as needed
    };

    return (
        <div className='h-screen'>
            <div className='mt-2 border-b'>
                <img
                    width={180}
                    className='mx-auto mb-2 p-2'
                    src={logo}
                    alt="Logo"
                />
            </div>
            <div>
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={`${showDropdown === item.name ? 'bg-slate-50' : ''} cursor-pointer`}
                        onClick={() => handleItemClick(item.name)}
                    >
                        <div className='flex justify-between p-4'>
                            <NavLink
                                to={item.path || '#'} // Navigate to the respective path if available
                                className='text-sm font-semibold text-[#6E707E]'
                            >
                                {item.name}
                            </NavLink>
                            {item.dropdownItems.length > 0 && (
                                <div className='text-xs font-semibold text-[#6E707E] my-auto'>
                                    {showDropdown === item.name ? '︽' : '〉'}
                                </div>
                            )}
                        </div>
                        {showDropdown === item.name && item.dropdownItems.length > 0 && (
                            <div className='pl-4'>
                                {item.dropdownItems.map((dropdownItem, index) => (
                                    <NavLink
                                        key={index}
                                        to={navigationPaths[dropdownItem]} // Navigate to the respective path
                                        className='block p-2 hover:bg-gray-100'
                                        activeClassName='text-blue-500' // Optional: Apply active class if needed
                                    >
                                        <div className='text-sm font-semibold text-[#6E707E]'>
                                            {dropdownItem}
                                        </div>
                                    </NavLink>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
